import { Helmet } from 'react-helmet';
import { useStrapi } from "shared/context/strapi-graphql";
import { collectionQueryMap } from "shared/context/collectionQueryMap";
import StrapiDynamicZone from "components/Strapi/DynamicZone";

import { StandardHero } from "components/Hero";
import { VideoPlayerWithWrapper } from "components/VideoPlayer/VideoPlayer";
import { FilteredList } from "components/FilteredList/FilteredList";
import { ResourceCard } from "components/ResourceCard/ResourceCard";

import './_index.scss';
import { FourRowIcon } from "components/CopyBlocks/ColumnList";

const ArticleTemplate = ({ slug }: { slug: string }): React.ReactElement | null => {
  const { pageData } = useStrapi({
    collections: collectionQueryMap(['Article Pages']),
    slug: slug
  });

  const componentsData = pageData[0]?.components;
  const seoData = pageData[0]?.attributes.seo;
  if (!componentsData) return null;

  return (
    <div>
      <Helmet>
        <title>{seoData.meta_title}</title>
        <meta name='description' content={seoData.meta_description} />
      </Helmet>
      <StrapiDynamicZone
        components={{
          Video: VideoPlayerWithWrapper,
          StandardHero: StandardHero,
          FilteredList: FilteredList,
          ResourceCard: ResourceCard,
          FourRowIcon: FourRowIcon,
        }}
        data={componentsData} // Pass only the components array
      />
    </div>
  )
}

export default ArticleTemplate;
