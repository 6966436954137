import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Calendar as Cal, dayjsLocalizer, Event } from 'react-big-calendar';
import Wrapper from 'components/Strapi/Wrapper';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { PayloadItem } from 'shared/lib/api/listViews';
import { Color } from 'shared/interfaces/Color';
import { mapColor } from 'shared/helpers/colorMapping';
import { EventCard } from './EventCard';
import ReactPaginate from 'react-paginate';
import './stylesheets/calendar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface ColorMap {
  key: string;
  color: Color;
}

interface CalendarLegendProps {
  colorMaps: ColorMap[];
}

interface CalendarAttributes {
  titleAttribute: string;
  startDTAttribute: string;
  endDTAttribute: string;
  urlAttribute?: string;
  colorMap: ColorMap[];
  colorMapAttribute: string;
}

interface CalendarProps extends CalendarAttributes {
  payload: PayloadItem[];
  onClick?: (event: EventExtras) => void;
}

export interface EventExtras extends Event {
  url?: string;
  bgColor?: string;
}
interface FeaturedCardCalendarProps extends CalendarAttributes {
  payload: PayloadItem[];
}

const localizer = dayjsLocalizer(dayjs);

export const Calendar: React.FC<CalendarProps> = ({
  payload,
  titleAttribute,
  startDTAttribute,
  endDTAttribute,
  urlAttribute,
  colorMapAttribute,
  colorMap,
  onClick,
}) => {
  const events = payload;

  if (!events) {
    return <></>;
  }

  const parsedEvents: EventExtras[] = events.map((event) => {
    const bgColor = getEventBGColor(event, colorMapAttribute, colorMap);

    return {
      start: dayjs(event[`${startDTAttribute}`]).toDate(),
      end: dayjs(event[`${endDTAttribute}`]).toDate(),
      title: event[`${titleAttribute}`],
      url: urlAttribute ? event[`${urlAttribute}`] : undefined,
      bgColor: bgColor,
    };
  });

  return (
    <Wrapper>
      <Cal
        localizer={localizer}
        events={parsedEvents}
        defaultDate={new Date()}
        defaultView="month"
        style={{ height: '100vh' }}
        onSelectEvent={onClick}
        eventPropGetter={eventStyleGetter}
      />
      {colorMap && <CalendarLegend colorMaps={colorMap} />}
    </Wrapper>
  );
};

const CalendarLegend: React.FC<CalendarLegendProps> = ({ colorMaps }) => {
  return (
    <div className="legend-container">
      {colorMaps.map((colorMap, index) => (
        <div className="legend-item" key={index}>
          <div
            className={`color-box background--${mapColor(
              colorMap.color.selectedColor
            )}`}
          ></div>
          <span className="color-label">{colorMap.key}</span>
        </div>
      ))}
    </div>
  );
};

const getEventBGColor = (
  payloadItem: PayloadItem,
  colorMapAttribute: string,
  colorMap: ColorMap[]
) => {
  if (!payloadItem[colorMapAttribute]) {
    return '';
  }
  const color = colorMap.find(
    (color) => color.key === payloadItem[colorMapAttribute]
  );
  return color ? color.color.selectedColor : '';
};

const eventStyleGetter = (event: EventExtras) => {
  return {
    className: event.bgColor ? `background--${mapColor(event.bgColor)}` : '',
  };
};

export const FeaturedCardCalendar: React.FC<FeaturedCardCalendarProps> = ({
  payload,
  titleAttribute,
  startDTAttribute,
  endDTAttribute,
  colorMapAttribute,
  colorMap,
}) => {
  const itemsPerPage = 6;
  const [itemOffset, setItemOffset] = useState(0);

  const now = new Date();

  // Filter events to show only upcoming events and sort by closest to today
  const upcomingEvents = payload
    .filter(event => new Date(event[startDTAttribute]) > now)
    .sort((a, b) => {
      const dateA = new Date(a[startDTAttribute]).getTime();
      const dateB = new Date(b[startDTAttribute]).getTime();
      return dateA - dateB;
    });

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = upcomingEvents.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(upcomingEvents.length / itemsPerPage);

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % upcomingEvents.length;
    setItemOffset(newOffset);
  };

  if (!upcomingEvents.length) {
    return <h2>No upcoming events found.</h2>;
  }

  return (
    <>
      <div className="event-cards">
        {currentItems.map((event, index) => {
          const bgColor = getEventBGColor(event, colorMapAttribute, colorMap);
          const { className } = eventStyleGetter({ ...event, bgColor });

          return (
            <EventCard
              key={index}
              event={event}
              titleAttribute={titleAttribute}
              startDTAttribute={startDTAttribute}
              endDTAttribute={endDTAttribute}
              colorMapAttribute={colorMapAttribute}
              colorMap={colorMap}
              className={className}
            />
          );
        })}
      </div>
      <ReactPaginate
        breakLabel="..."
        nextLabel={<FontAwesomeIcon icon={"chevron-right"} />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={6}
        pageCount={pageCount}
        previousLabel={<FontAwesomeIcon icon={"chevron-left"} />}
        renderOnZeroPageCount={null}
        containerClassName="pagination"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="prev-page"
        nextClassName="next-page"
        activeClassName="active"
        disabledClassName="disabled"
      />
    </>
  );
};