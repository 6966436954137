export const strapiGraphqlQueryCollection = `#graphql
  id
`;

// ======================= Image =======================

export const strapiGraphqlQueryImage = `#graphql
  image {
    data {
      attributes {
        alternativeText
        url
        caption
      }
    }
  }
  lazy
`;

// ======================= Link =======================

export const strapiGraphqlQueryLink = `#graphql
  href
  target
  rel
  ariaLabel
`;

// ======================= Icon =======================

export const strapiGraphqlQueryIcon = `#graphql
  id
  icon
`;

// ======================= Default Page Collection Data =======================

export const strapiGraphqlQueryPageCollection = `#graphql
  ${strapiGraphqlQueryCollection}
  attributes {
    seo {
      meta_title
      meta_description
    }
    slug
    title
    publishedAt
    previewImg {
    ${strapiGraphqlQueryImage}
   }
    description
  }
`;

// ======================= Default Page Collection Data =======================

const strapiGraphqlQueryResoucePart = `#graphql
  title
  description
  previewImg {
    ${strapiGraphqlQueryImage}
  }
  publishedAt
`
export const strapiGraphqlQueryResourcePageCollection = `#graphql
  attributes {
    ${strapiGraphqlQueryResoucePart}
    slug
  }
`;

// ======================= Default Link Collection Data =======================

export const strapiGraphqlQueryResourceLinkCollection = `#graphql
  attributes {
    ${strapiGraphqlQueryResoucePart}
    link {
      ${strapiGraphqlQueryLink}
    }
  }
`;

// ======================= IconUrl =======================

export const strapiGraphqlQueryIconUrl = `#graphql
    id
    href
    target
    rel
    ariaLabel
`;

// ======================= SEO =======================

export const strapiGraphqlQuerySeo = `#graphql
  seo {
    metaTitle
    metaDescription
    canonicalURL
    metaRobots
    metaImage {
      ${strapiGraphqlQueryImage}
    }
  }
`;

// ======================= Color =======================

export const strapiGraphqlQueryColor = `#graphql
  selectedColor
`;

// ======================= Navigation Options =======================

export const strapiGraphqlQueryNavigationOptions = `#graphql
  header {
    enabled
  }
  footer {
    enabled
  }
  responsiveNav {
    enabled
  }
  colorTheme {
    theme
  }
`;

// ======================= backgroundImage =======================

export const strapiGraphqlQueryBackgroundImage = `#graphql
  backgroundImage {
    ${strapiGraphqlQueryImage}
  }
  backgroundImageMobile {
    ${strapiGraphqlQueryImage}
  }
  backgroundImageAlignment
`;

// ======================= Spacing =======================

export const strapiGraphqlQuerySpacing = `#graphql
  marginBottom
  marginTop
  paddingBottom
  paddingTop
`;

// ======================= ComponentGlobals =======================

export const strapiGraphqlQueryComponentGlobals = `#graphql
  componentId
  className
  spacing {
    ${strapiGraphqlQuerySpacing}
  }
`;

// ======================= Button =======================

export const strapiGraphqlQueryButton = `#graphql
  id
  label
  link {
    ${strapiGraphqlQueryLink}
  }
  icon
  className
`;

// ======================= Image Carousel =======================

export const strapiGraphqlQueryImageCarousel = `#graphql
  header
  content
  imageAlignment
  image {
    ${strapiGraphqlQueryImage}
  }
  button {
    ${strapiGraphqlQueryButton}
  }
`;

export const strapiGraphqlQueryImageCarouselComponent = `#graphql
  ...on ComponentImageCarousel {
    ${strapiGraphqlQueryComponentGlobals}
    ${strapiGraphqlQueryImageCarousel}
  }
`;

// ======================= Icon Banner =======================

export const strapiGraphqlQueryIconBanner = `#graphql
  heading
  headingHighlight
  bgColor {
    ${strapiGraphqlQueryColor}
  }
  variation
  icons {
    ${strapiGraphqlQueryImage}
  }
  icon {
    ${strapiGraphqlQueryImage}
  }
  directions1
  directions2
  contactNumber
  faxNumber
  email
  connectIcon {
    ${strapiGraphqlQueryImage}
  }
  connectLink {
    ${strapiGraphqlQueryLink}
  }
`;

export const strapiGraphqlQueryIconBannerComponent = `#graphql
  ...on ComponentCfrComponentsIconBanner {
    ${strapiGraphqlQueryIconBanner}
  }
`;

// ======================= Tabbed Nav =======================

export const strapiGraphqlQueryTabbedNavTab = `#graphql
  title
  collection
  discoverPageLink {
    data {
      ${strapiGraphqlQueryPageCollection}
    }
  }
  engagePageLink {
    data {
      ${strapiGraphqlQueryPageCollection}
    }
  }
  givePageLink {
    data {
      ${strapiGraphqlQueryPageCollection}
    }
  }
  receivePageLink {
    data {
      ${strapiGraphqlQueryPageCollection}
    }

  }
  otherPageLink {
    data {
      ${strapiGraphqlQueryPageCollection}
    }

  }
`;

export const strapiGraphqlQueryTabbedNav = `#graphql
  tabs {
    data {
      attributes {
        tabs {
          ${strapiGraphqlQueryTabbedNavTab}
          subTabs {
            ${strapiGraphqlQueryTabbedNavTab}
          }
        }
      }
    }
  }
`;

export const strapiGraphqlQueryTabbedNavComponent = `#graphql
  ...on ComponentTabbedNavTabbedNav {
    ${strapiGraphqlQueryTabbedNav}
  }
`;

// ======================= Column Cards =======================

export const strapiGraphqlQueryColumnCards = `#graphql
  id
  cards {
    imageUrl {
      ${strapiGraphqlQueryImage}
    }
    iconUrl {
      ${strapiGraphqlQueryImage}
    }
    title
    description
    cta1 {
      link {
        ${strapiGraphqlQueryLink}
      }
      text
    }
    cta2 {
      link {
        ${strapiGraphqlQueryLink}
      }
      text
    }
  }
  columnType
  imageType
  bgColor {
    ${strapiGraphqlQueryColor}
  }
  isMobileCarousel
`;

export const strapiGraphqlQueryColumnCardsComponent = `#graphql
  ...on ComponentColumnCardsColumnCards {
    ${strapiGraphqlQueryColumnCards}
  }
`

// ======================= Column Cards with Background Image =======================

export const strapistrapiGraphqlQueryColumnCardsBGImage = `#graphql
  cards {
    id
    imageUrl {
        ${strapiGraphqlQueryImage}
    }
    title
    description
    cta {
        ${strapiGraphqlQueryButton}
    }
  }
`
export const strapiGraphqlQueryColumnCardsBGImageComponent = `#graphql
  ... on ComponentColumnCardsColumnCardsWithBackgroundImage {
    ${strapistrapiGraphqlQueryColumnCardsBGImage}
    variation
    bgColor {
      ${strapiGraphqlQueryColor}
    }
  }
`

// ======================= Color Divider =======================

export const strapiGraphqlQueryColorDivider = `#graphql
  className
`;

export const strapiGraphqlQueryColorDividerComponent = `#graphql
  ...on ComponentColorDividerColorDivider {
    ${strapiGraphqlQueryColorDivider}
  }
`;

// ======================= Hero =======================

export const deprecatedStrapiGraphqlQueryStandardHeroComponent = `#graphql
  ... on ComponentCfrComponentsHero {
    Title
    SubTitle
    id
    bgImg {
      ${strapiGraphqlQueryImage}
    }
  }
`; // to be deprecated

export const strapiGraphqlQueryStandardHeroComponent = `#graphql
... on ComponentHeroesStandardHero {
  Title
  SubTitle
  id
  bgImg {
    ${strapiGraphqlQueryImage}
  }
  cta {
    ${strapiGraphqlQueryButton}
  }
  height
  gradient
  vimeoID
}
`;

// ======================= Connect with Expert =======================
export const strapiGraphqlQueryConnectwithExpert = `#graphql
  ... on ComponentCopyBlocksConnectWithExpert {
    id
    title
    desc
    cta {
      ${strapiGraphqlQueryButton}
    }
  }
`;

// ======================= Accordions =======================

export const strapiGraphqlQueryAccordionData = `#graphql
  topTitle: title
  bgColor {
    ${strapiGraphqlQueryColor}
  }
  content {
    bottomHeader: header
    bottomDescription: description
    buttons {
      ${strapiGraphqlQueryButton}
    }
  }
`;

// ======================= Intro - 2 Column =======================
export const strapiGraphqlQueryIntro2Column = `#graphql
  ... on ComponentIntrosH1TextTwoColumn {
    id
    h1
    p
}
`;

export const strapiGraphqlQueryAccordionComponent = `#graphql
  ...on ComponentAccordionsFaqsAccordion {
    title
    description
    sectionBgColor {
      ${strapiGraphqlQueryColor}
    }
    accordion {
      ${strapiGraphqlQueryAccordionData}
      content {
        bottomHeader2: header2
      }
    }
  }
`;

export const strapiGraphqlQueryMegaAccordionComponent = `#graphql
  ...on ComponentAccordionsMegaAccordion {
    sectionBgColor {
      ${strapiGraphqlQueryColor}
    }
    accordion {
      ${strapiGraphqlQueryAccordionData}
      topSubTitle: subTitle
      topImage: image {
        ${strapiGraphqlQueryImage}
      }
      topDescription: description
      content {
        bottomImages: images {
          ${strapiGraphqlQueryImage}
        }
      }
    }
  }
`;

export const strapiGraphqlQueryTwoColumnAccordionComponent = `#graphql
  ...on ComponentAccordionsTwoColumnAccordion {
    sectionBgColor {
      ${strapiGraphqlQueryColor}
    }
    accordion {
      heading
      subHeading
      image {
        ${strapiGraphqlQueryImage}
      }
      description
      reversed
      accordion {
        accordion {
          ${strapiGraphqlQueryAccordionData}
        }
      }
    }
  }
`;

// Two Column Layout

export const strapiGraphqlQueryTwoColumnLayout = `#graphql
... on ComponentImageAndCopyBlocksTwoColumnLayout {
  id
  h2
  h3
  p
  cta{
    ${strapiGraphqlQueryButton}
  }
  image {
    ${strapiGraphqlQueryImage}
  }
  optionalRte
  class_name
  is_hidden
  variation
  bgColor {
    ${strapiGraphqlQueryColor}
  }
  reversed
  fontColor
}
`;

// ======================= Video =======================

export const strapiGraphqlQueryVideo = `#graphql
  ... on ComponentVideoVideo {
    videoType
    videoId
   }
`

// ======================= Tri Cards =======================

export const strapiGraphqlQueryTriCardCard = `#graphql
    id
    heading
    body
    date
    button {
      ${strapiGraphqlQueryButton}
    }
    link {
      ${strapiGraphqlQueryLink}
    }
`;

export const strapiGraphqlQueryTriCards = `#graphql
  ... on ComponentTriCardsTriCards {
    card1 {
      ${strapiGraphqlQueryTriCardCard}
    }
    card2 {
      ${strapiGraphqlQueryTriCardCard}
    }
    card3 {
      ${strapiGraphqlQueryTriCardCard}
      image {
        ${strapiGraphqlQueryImage}
      }
    }
  }
`;

// ======================= Tabbed Carousel =======================

export const strapiGraphqlQueryCarouselTab = `#graphql
  id
  buttonHeader
  image {
    ${strapiGraphqlQueryImage}
  }
  storyHeader
  content
`;
export const strapiGraphqlQueryTabbedCarouselComponent = `#graphql
  ... on ComponentCarouselsTabbed {
    header
    tabs {
      ${strapiGraphqlQueryCarouselTab}
    }
    variation
    bgColor {
      ${strapiGraphqlQueryColor}
    }
  }
`;
// ======================= Featured Card Carousel =======================

export const strapiGraphqlQueryFeaturedCard = `#graphql
  id
  storyHeader
  image {
    ${strapiGraphqlQueryImage}
  }
  date
  storyType
  content
  author
  cta {
    ${strapiGraphqlQueryButton}
  }
`

export const strapiGraphqlQueryFeaturedCarousel = `#graphql
  ... on ComponentCarouselsFeaturedCarousel {
    cards {
      ${strapiGraphqlQueryFeaturedCard}
    }
    bgColor {
      ${strapiGraphqlQueryColor}
    }
  }
`
// ======================= H1 Intro =======================

export const strapiGraphqlQueryH1IntroText = `#graphql
  ... on ComponentIntrosH1TextIntro {
    id
    title
    desc
    variation
    cta {
      ${strapiGraphqlQueryButton}
    }
  }
`

// ======================= H1 Infographic =======================

export const strapiGraphqlQueryH1InfographicFacts = `#graphql
  id
  metricType
  metricTitle
  className
  bgColor {
    ${strapiGraphqlQueryColor}
  }
  variation
  fontColor
`

export const strapiGraphqlQueryH1Infographic = `#graphql
  ... on ComponentIntrosH1Infographic {
    id
    metricHeader
    metricParagraph
    isCarousel
    metricFacts {
      ${strapiGraphqlQueryH1InfographicFacts}
    }
  }
`

// ======================= H1 Intro Single Column =======================

export const strapiGraphqlQueryH1IntroSingleColumn = `#graphql
  ... on ComponentIntrosH1TextSingleColumn {
    id
    title
    desc
    cta {
      ${strapiGraphqlQueryButton}
    }
    bgColor {
      ${strapiGraphqlQueryColor}
    }
  }
`

// ======================= H1 Intro Three Column =======================

export const strapiGraphqlQueryH1IntroThreeColumn = `#graphql
  ... on ComponentIntrosH1TextThreeColumn {
    id
    bgColor {
      ${strapiGraphqlQueryColor}
    }
    header
    title
    desc
    cta {
      ${strapiGraphqlQueryButton}
      }
  }
`
// ======================= Text Two Column List =======================

export const strapiGraphqlQueryTextTwoColumnItem = `#graphql
  id
  title
  subHeader
  desc
  cta {
    ${strapiGraphqlQueryButton}
  }
  border
`

export const strapiGraphqlQueryTextTwoColumnList = `#graphql
... on ComponentCopyBlocksTwoColumnList {
    singleColumnIntro {
      ${strapiGraphqlQueryH1IntroSingleColumn}
    }
    items {
      ${strapiGraphqlQueryTextTwoColumnItem}
    }
    bgColor {
        ${strapiGraphqlQueryColor}
      }
  }
`

// ======================= Text Two Column List =======================

export const strapiGraphqlQueryTextTwoColumnItemWithIcon = `#graphql
  text
  link {
    ${strapiGraphqlQueryLink}
  }
  icon
  buttonIcon
  iconColor {
    ${strapiGraphqlQueryColor}
  }
`

export const strapiGraphqlQueryTwoColumnListWithIcons = `#graphql
... on ComponentCopyBlocksTwoColumnListWithIcons {
    twoColumnList {
      content {
        ${strapiGraphqlQueryTextTwoColumnItemWithIcon}
      }
      bgColor {
          ${strapiGraphqlQueryColor}
      }
    }
  }
`

// ======================= Photo Collage =======================

export const strapiGraphqlQueryPhotoCollage = `#graphql
  ... on ComponentPhotoCollagePhotoCollage {
    title
    description
    button {
      ${strapiGraphqlQueryButton}
    }
    items {
      image {
        ${strapiGraphqlQueryImage}
      }
      button {
        ${strapiGraphqlQueryButton}
      }
    }
    align
    barColor {
      ${strapiGraphqlQueryColor}
    }
    transparentLogo
  }
`;

// ======================= Focus Carousel =======================

export const strapiGraphqlQueryFocusSlide = `#graphql
  id
  image {
    ${strapiGraphqlQueryImage}
  }
  title
  description
  cta {
    ${strapiGraphqlQueryButton}
  }
`

export const strapiGraphqlQueryFocusCarousel = `#graphql
  ... on ComponentCarouselsFocusCarousel {
    id
    slides {
      ${strapiGraphqlQueryFocusSlide}
    }
    bgColor {
      ${strapiGraphqlQueryColor}
    }
    title
    description
  }
`
// ======================= Three Column Carousel =======================

export const strapiGraphqlQueryThreeColumnCarousel = `#graphql
  ... on ComponentCarouselsThreeColumnCarousel {
    id
    image {
      ${strapiGraphqlQueryImage}
    }
    bgColor {
      ${strapiGraphqlQueryColor}
    }
  }
`
// ======================= Five Column Bio Cards =======================

export const strapiGraphqlQueryFiveColumnBioProfiles = `#graphql
  id
  image {
    ${strapiGraphqlQueryImage}
  }
  fullName
  itemOne
  branch
  itemTwo
  other
  company
  description
`

export const strapiGraphqlQueryFiveColumnBioCards = `#graphql
  ... on ComponentCardsFiveColumnBioCards {
    id
    profile {
      ${strapiGraphqlQueryFiveColumnBioProfiles}
    }
    bgColor {
      ${strapiGraphqlQueryColor}
    }
  }
`

// ======================= Filtered Lists =======================

export const strapiGraphqlQueryFilteredList = `#graphql
  ... on ComponentFilteredListFilteredList {
    collections: Collections
    itemsPerPage
    frequentlySearchedTerms {
      term
    }
    displayDropDown
  }
`

export const strapiGraphqlQueryAlgoliaFilteredList = `#graphql
  ... on ComponentFilteredListAlgoliaFilteredList {
    itemsPerPage
  }
`

// ======================= Resource Card =======================

export const strapiGraphqlQueryResourceCard = `#graphql
  ... on ComponentResourceCardResourceCard {
    id
    datePosted
    title
    subTitle
    description
    file {
      data {
        attributes {
          url
        }
      }
    }
  }
`

// ======================= News Carousel =======================

export const strapiGraphqlQueryNewsCarouselTabs = `#graphql
  id
  image {
    ${strapiGraphqlQueryImage}
  }
  storyType
  storyHeader
  content
  cta {
    ${strapiGraphqlQueryButton}
  }
  url
`

export const strapiGraphqlQueryNewsCarousel = `#graphql
  ... on ComponentCarouselsNewsCarousel {
    id
    tabs {
      ${strapiGraphqlQueryNewsCarouselTabs}
    }
    bgColor {
      ${strapiGraphqlQueryColor}
    }
  }
`

// ======================= Image Card Carousel 50/50 =======================

export const strapiGraphqlQueryImageCardCarousel5050Tabs = `#graphql
  id
  image {
    ${strapiGraphqlQueryImage}
  }
  title
  description
  cta {
    ${strapiGraphqlQueryButton}
  }
`

export const strapiGraphqlQueryImageCardCarousel5050 = `#graphql
  ... on ComponentCarouselsImageCard5050Carousel {
    id
    tabs {
      ${strapiGraphqlQueryImageCardCarousel5050Tabs}
    }
    bgColor {
      ${strapiGraphqlQueryColor}
    }
    imageAlignment
  }
`
// ======================= Three Column List =======================

// export const strapiGraphqlQueryThreeColumnListItem = `#graphql
//       id
//       item
// `

export const strapiGraphqlQueryThreeColumnItem = `#graphql
  id
  header
  description
  listItems
`

export const strapiGraphqlQueryThreeColumnList = `#graphql
  ... on ComponentCopyBlocksThreeColumnList {
    id
    header
    columnList {
      ${strapiGraphqlQueryThreeColumnItem}
    }
    bgColor {
      ${strapiGraphqlQueryColor}
    }
  }
`



// ======================= Image Card Carousel 50/50 =======================


export const strapiGraphqlQueryH1ImageCarouselTabs = `#graphql
  id
  image {
    ${strapiGraphqlQueryImage}
  }
  title
`

export const strapiGraphqlQueryH1ImageCarousel = `#graphql
  ... on ComponentCarouselsH1ImageCarousel {
    id
    tabs {
      ${strapiGraphqlQueryH1ImageCarouselTabs}
    }
    bgColor {
      ${strapiGraphqlQueryColor}
    }
    introH1 {
      ${strapiGraphqlQueryH1IntroText}
    }
  }
`

export const strapiGraphqlQueryListView = `#graphql
  ... on ComponentListviewsListview {
    id
    objectType
    listViewID
    displayOptions
    attributeOverride
    recordsPerPage
    urlAttribute
    bgColor {
      ${strapiGraphqlQueryColor}
    }
    sortColumnIndex
  }
`
// ======================= Resource List =======================

export const strapiGraphqlQueryResourceListItem = `#graphql
  id
  title
  description
  isAlternate
  cta {
      ${strapiGraphqlQueryButton}
    }
`

export const strapiGraphqlQueryResourceList = `#graphql
  ... on ComponentCopyBlocksResourceList {
    id
    title
    description
    listItems {
      ${strapiGraphqlQueryResourceListItem}
    }
    bgColor {
      ${strapiGraphqlQueryColor}
    }
  }
`
// ======================= Calendar =======================

export const strapiGraphqlQueryCalendar = `#graphql
  ... on ComponentCalendarsCalendar {
    id
    title
}
`

// =========== CTA Image Scroller Carousel ==============

export const strapiGraphqlQueryCtaImageScrollerTabs = `#graphql
    id
    image {
      ${strapiGraphqlQueryImage}
    }
    cta {
      ${strapiGraphqlQueryButton}
    }
`

export const strapiGraphqlQueryCtaImageScrollerCarousel = `#graphql
  ... on ComponentCarouselsCtaImageScrollerCarousel {
    id
    tabs {
      ${strapiGraphqlQueryCtaImageScrollerTabs}
    }
    introH1Component {
      ${strapiGraphqlQueryH1IntroText}
    }
    bgColor {
      ${strapiGraphqlQueryColor}
    }
}
`

export const strapiGraphqlQuerySixtyFortyFundType = `#graphql
  ... on ComponentCopyBlocks6040FundType {
    id
    content1
    content2
    bgColor {
      ${strapiGraphqlQueryColor}
    }
}
`
//================= Table Accordion ===================

export const strapiGraphqlQueryTableAccordionData = `#graphql
    title
    sectionBgColor {
      ${strapiGraphqlQueryColor}
    }
    description
`;
export const strapiGraphqlQueryTableAccordion = `#graphql
  ...on ComponentAccordionsTableAccordion {
    bgColor {
      ${strapiGraphqlQueryColor}
    }
    accordion {
      ${strapiGraphqlQueryTableAccordionData}
    }
  }
`;

// Generic Key/Value
const strapiGraphqlGenericKeyValue = `#graphql
  id
  key
  value
`

// Four Column Table

export const strapiGraphqlFourColumnTable = `#graphql
  ...on ComponentCopyBlocksFourColumnTable {
    cards {
      id
      heading
      headingBGColor {
        ${strapiGraphqlQueryColor}
      }
      contentBGColor {
        ${strapiGraphqlQueryColor}
      }
      details {
        ${strapiGraphqlGenericKeyValue}
      }
      subheading1
      subheading1Details {
        ${strapiGraphqlGenericKeyValue}
      }
      subheading2
      subheading2Details {
        ${strapiGraphqlGenericKeyValue}
      }
    }
  }

`

export const strapiGraphqlColorMap = `#graphql
  key
  color {
    ${strapiGraphqlQueryColor}
  }
`
export const strapiGraphqlListViewCalendar = `#graphql
  ... on ComponentListviewsCalendar {
    id
    objectType
    listViewID
    filterAttribute
    titleAttribute
    filterName
    startDTAttribute
    endDTAttribute
    urlAttribute
    colorMapAttribute
    colorMap {
      ${strapiGraphqlColorMap}
    }
    bgColor {
      ${strapiGraphqlQueryColor}
    }
    isCardLayout
  }
`
// Four Row Icon

const strapiGraphqlQueryFourRowIconData = `#graphql
  id
  iconBackground {
    ${strapiGraphqlQueryColor}
  }
  descriptionBackground {
    ${strapiGraphqlQueryColor}
  }
  iconColor {
    ${strapiGraphqlQueryColor}
  }
  dividerBgColor {
    ${strapiGraphqlQueryColor}
  }
  faHeader
  contentTitle
  contentDescription
  benefitsTitle
  benefitsBulletPoints
  icon {
    ${strapiGraphqlQueryIcon}
  }
`

export const strapiGraphqlFourRowIcon = `#graphql
  ...on ComponentCopyBlocksFourRowIcon {
      id
      bgColor {
        ${strapiGraphqlQueryColor}
      }
      fourRowIcon {
        ${strapiGraphqlQueryFourRowIconData}
      }
  }

`
