import React, { useState, useEffect } from 'react';
import {
  fetchDataWithQueryParams,
  PayloadItem,
} from 'shared/lib/api/listViews';
import { Calendar, EventExtras, ColorMap, FeaturedCardCalendar } from 'components/Calendar/Calendar';
import { SearchInput, DropDown } from 'components/Inputs';
import { openInNewWindow, toAbsoluteUrl } from 'shared/helpers/navHelpers';
import Wrapper from 'components/Strapi/Wrapper';


interface ListViewCalendarProps {
  objectType: string;
  listViewID: string;
  filterAttribute: string;
  titleAttribute: string;
  filterName: string;
  startDTAttribute: string;
  endDTAttribute: string;
  urlAttribute?: string;
  colorMapAttribute: string;
  colorMap: ColorMap[];
  bgColor?: {
    selectedColor: string
  };
  isCardLayout?: boolean;
}

const ListViewCalendar: React.FC<ListViewCalendarProps> = ({
  objectType,
  listViewID,
  isCardLayout,
  ...props
}) => {
  const [records, setRecords] = useState<PayloadItem[] | []>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [filteredRecords, setFilteredRecords] = useState<PayloadItem[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>(''); // Search input state
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]); // Dropdown filters state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const results = await fetchDataWithQueryParams('list_view_records', {
          'object_type': objectType,
          'listview_id': listViewID,
        });

        setRecords(results);
        setFilteredRecords(results);
      } catch (error) {
        setError('There are currently no postings available for this category');
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [objectType, listViewID]);

  // Function to apply both search and dropdown filters
  const applyFilters = () => {
    let filtered = records;

    // Apply dropdown filter
    if (selectedFilters.length > 0) {
      filtered = filtered.filter((record) =>
        selectedFilters.includes(record[props.filterAttribute])
      );
    }

    // Apply search filter
    if (searchTerm) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((record) =>
        record.title.toLowerCase().includes(lowerCaseSearchTerm) ||
        (record["event_location"] &&
          record["event_location"].toLowerCase().includes(lowerCaseSearchTerm))
        ||
        (record["organization__r.name"] &&
          record["organization__r.name"].toLowerCase().includes(lowerCaseSearchTerm))
      );
    }

    setFilteredRecords(filtered);
  };

  // Update search term state and reapply filters
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // Update selected filters state and reapply filters
  const handleFilterChange = (selected: string[]) => {
    setSelectedFilters(selected);
  };

  // Reapply filters whenever searchTerm or selectedFilters changes
  useEffect(() => {
    applyFilters();
  }, [searchTerm, selectedFilters, records]);

  const handleEventClick = (event: EventExtras) => {
    if (props.urlAttribute && event.url) {
      openInNewWindow(toAbsoluteUrl(event.url));
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div><h2 className="error-message">{error}</h2></div>;
  if (!records.length) return <></>;

  return (
    <Wrapper bgColor={props?.bgColor?.selectedColor}>
      {isCardLayout ?
        <>
          <FeaturedCardCalendar
            payload={filteredRecords}
            titleAttribute={props.titleAttribute}
            startDTAttribute={props.startDTAttribute}
            endDTAttribute={props.endDTAttribute}
            colorMap={props.colorMap}
            colorMapAttribute={props.colorMapAttribute}
          />
        </>
        :
        <>
          <SearchInput
            name="Calendar"
            placeholder="Search by title or organization"
            onChange={handleSearchChange}
            stateValue={searchTerm}
          />
          <DropDown
            dropDownName="Filter"
            options={getFilters(records, props.filterAttribute)}
            onSelectionChange={handleFilterChange}
          />
          <Calendar
            payload={filteredRecords}
            titleAttribute={props.titleAttribute}
            startDTAttribute={props.startDTAttribute}
            endDTAttribute={props.endDTAttribute}
            urlAttribute={props.urlAttribute}
            colorMapAttribute={props.colorMapAttribute}
            colorMap={props.colorMap}
            onClick={handleEventClick}
          />
        </>
      }
    </Wrapper>
  );
};

const getFilters = (
  records: PayloadItem[],
  filterAttribute: string
): string[] =>
  Array.from(new Set(records.map((record) => record[filterAttribute])));

export default ListViewCalendar;
